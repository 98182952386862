import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const PhysicalSupply = () => {
    return (
        <Layout>
            <Seo title="Physical Supply" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Physical Supply</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p>
                            Delta Energy offers a broad range of marine fuel products complying with all international standardards at
                            competitive prices.
                        </p>
                        <p></p>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <div className="row">
                            <div className="col">
                                <div className="mb-2">
                                    <h2 className="mb-0">Amsterdam, Rotterdam, Antwerp</h2>
                                    <span className="text-muted">And all surroundings ports</span>
                                </div>
                                <p>
                                    <span className="badge bg-secondary me-2">RMG380 3.5%</span>
                                    <span className="badge bg-secondary me-2">VLSFO (RMG380) 0.5%</span>
                                    <span className="badge bg-secondary me-2">ULSFO (RMD80) 0.1%</span>
                                    <span className="badge bg-secondary me-2">DMA 0.1% 0.89</span>
                                    <span className="badge bg-secondary">DMA 0.1% 0.86 (winter grade)</span>
                                </p>
                                <p>
                                    Delta Energy have a fully time-chartered state of the art marine fuel barge for supply throughout
                                    the ARA area, built 2007, and looking to build on its fleet size alongside its first-class barge
                                    owner which has a refreshing and efficient name in the ARA market.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3 mb-md-0 col-md-6">
                                <StaticImage
                                    width={624}
                                    aspectRatio={3 / 2}
                                    className="rounded"
                                    src="../../../images/service/tilldeymann.jpg"
                                    alt="Till Deymann"
                                />
                            </div>
                            <div className="col-12 col-md-6"></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col">
                                <div className="mb-2">
                                    <h2 className="mb-0">Thames, UK</h2>
                                    <span className="text-muted">Thames, Medway and southend anchorages</span>
                                </div>
                                <p>
                                    <span className="badge bg-secondary">DMA 0.1% 0.86 (winter grade)</span>
                                </p>
                                <p>
                                    We supply Low Sulphur Marine Gas Oil and Ultra Low Sulphur Diesel (ULSD) by barge across all
                                    locations in the Thames and Medway region in accordance with ISO8217:2010 specification. Our
                                    product is imported from Majors and loaded from storage located on the Thames providing us with
                                    flexibility and quality assurance to meet our customers’ demands.
                                </p>
                                <p>
                                    We carry out supplies using a barge we have exclusively on time-charter, the Distributor. This
                                    barge is no stranger to the Thames having worked the river for many years, so with this vast
                                    experience we aim to provide excellent operational service and value to you.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3 mb-md-0 col-md-6">
                                <StaticImage
                                    width={624}
                                    aspectRatio={3 / 2}
                                    className="rounded"
                                    src="../../../images/service/distributor.jpg"
                                    alt="Distributor"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default PhysicalSupply
